import { global } from ":global";
import type { _Guarded } from ":shared/components/route";
import { default as routes } from "../../auth/routes";

// TODO: check token only once across routes
// TODO: disable token checks on /auth but not /auth/login
// TODO: store non critical indicators in local storage to know this
export const checkUserToken: _Guarded.AGuardStep = {
  runOn: ["app_init", "first_load"],
  loaderMsg: "checking token...",
  async checker({ state, base, routeMatch, routeMatchBase, location }) {
    let result: _Guarded.AGuardStepCheckerError = undefined;
    const is_auth_route = routeMatchBase(routes.BASE);

    if (is_auth_route) {
      // const is_auth_login_route = routeMatch(ROUTES.auth.login);
      // if (!is_auth_login_route) {
      // }
    } else {
      await global.api.auth.checkToken()?.catch?.((error) => {
        result = {
          message: "check token error !!",
          error,
        };
      });
    }
    return result;
  },
};

//! DISCLAIMER
//
// this folder and all it's subfolders must avoid the following:
// - importing index.ts of any kind outside of it to avoid circullar dependency
// - offer only the necessary needed parts as global
// remember, use of global vars isn't recommended because it makes
// dismantling the smaller pieces even harder. but it's unavoidable
// in cases like api and helpers.
//
// this folder doesn't adhere to the rest of project's folder structre of
// collection->namespace->collection->unit leveling due to it's unique nature of usage
//! DISCLAIMER

import { cookie as cookies } from "@qundus.tc/dom.helpers";
import { createAgnosticHelpers } from "@qundus.tc/agnostic.helpers";
import { createLoglevel } from "@qundus.tc/agnostic.log";

//
// import type { _Qunxios } from ":shared/utils/qunxios";
// import type { _Css } from "@qundus.tc/agnostic.helpers/css";
import type { _AgnosticLog, _LogLevel } from "@qundus.tc/agnostic.log";
import type { _AgnosticHelpers } from "@qundus.tc/agnostic.helpers";

const helper = createAgnosticHelpers({ envMode: import.meta.env.MODE });
const log = createLoglevel({ envMode: import.meta.env.MODE });
// const theme = css.createRootProps({ vars: whitelabel$.CONST.THEME_VARS });
// initilize and distribute shared first
if (helper.env.isProd) {
  console.log = log.dev.info;
  console.warn = log.dev.warn;
  console.trace = log.dev.trace;
  // console.error = log.dev.error;
}

//? to confirm that all loggers across all envs are working properly by sending initilizing message
log.all.enableAll();
log.dev.info("Shared initialized!");
log.stage.info("Shared initialized!");
log.prod.info("Shared initialized!");

// global contexts: import stores directly to avoid dev server circular dependency issues with HMR
import { default as store } from "./store";
import { default as contexts } from "./contexts";
import { default as api } from "./api";
import * as constants from "./constants";

// FIXME: causes issues for any sub files that use global, if any store file under modules imports {global} and is being used under global.store <- circullar dependency
export const global = {
  cookie: cookies.createCookie(),
  helper,
  log,
  contexts,
  store,
  api,
  constants,
};

import IconTableUser from "../assets/icons/table-user.svg";
import IconTableEmail from "../assets/icons/table-email.svg";
//
import { Table } from "../components/table";
import type { ApiResponse } from "../apis/_model";
import { EmptyTable } from "../components/empty-table";
import type { _SolidState } from ":shared/utils/state-manager";
import { cancelSentRequest } from "../apis/cancel-sent-request";
import { global } from ":global";

export function TableSentRequests(props: { teams: _SolidState.State<ApiResponse.GetTeams> }) {
  return (
    <Table
      rows={props.teams.value.sent_requests}
      fallback={<EmptyTable msg="You do not have any sent requests currently " />}
      classes={{
        desktop: {
          columnContainer: "bg#bg-default  font-semibold text-0.77rem rounded-t-6 ",
          singleColumn: "h-3.5rem ",
          singleRowContainer:
            " overflow-y-hidden h-3rem text-0.75rem min-h-5rem border-b-2 border-solid border-gray-100",
          singleRow: "",
        },
        phone: {
          cardsContainer: "space-y-5 !h-full",
          singleCardContainer:
            "shadow-md w-full !min-h-10rem py-2 border-2 border#bg-default border-solid rounded-xl !justify-between",
          allCardsRows: "!w-90% !justify-betwen py-1.5",
          singleCardHeader: "!text-0.5rem text-gray w-full",
          singleCardValue: "!w-fit !text-0.45rem",
        },
      }}
      columns={{
        User: {
          desktop: {
            // width: "40%",
            icon: <IconTableUser class="w-24px" />,
            class: "pl-8 flex-1",
          },
          phone: {
            cardHeaderClass: "!hidden !max-w-0 ",
            class: "border-b-2 border-solid border-gray-100",
          },
          key: (r) => {
            return <p class="flex flex-col  text-0.8rem <md:text-0.6rem">{`${r.name ?? ""}`}</p>;
          },
        },
        "Email Address": {
          desktop: {
            icon: <IconTableEmail class="w-24px" />,
            width: "20%",
          },
          phone: {},
          key: "email",
        },
        "Team Name": {
          desktop: {
            // width: "20%",
            icon: <i class="i-ph:users-three w-30px" />,
            class: "flex-[0.5]",
            // headerClass: "-ml-1",
          },
          phone: {
            cardValueClass: "whitespace-nowrap",
          },
          key: (r) => {
            return !r.team ? "No Team" : r.team;
          },
        },
        Action: {
          desktop: {
            width: "10%",
            headerClass: "-ml-1",
          },
          key: (r) => {
            return (
              <div class="flex flex-row space-x-2">
                <span
                  class="h-fit text-red underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "warn",
                      message: `Are you sure you want to withdraw request sent to ${r.email}  ?!`,
                      events: {
                        async onConfirmed() {
                          return cancelSentRequest({ id: r.id }).then(() => {
                            global.store.actions.alert.pushToast({
                              type: "success",
                              message: `request cancelled!`,
                            });
                            props.teams.set((s) => {
                              const sent_requests = s.sent_requests?.filter((x) => x.id !== r.id);
                              return { ...s, sent_requests };
                            });
                          });
                        },
                      },
                    });
                  }}
                >
                  Cancel
                </span>
              </div>
            );
          },
        },
      }}
    />
  );
}

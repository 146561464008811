import createInstance from "./instance";
import { checkOptions, checkEndpointURL, initAccessDenied } from "./helpers";
import createAuthAddon from "./auth";
import { interceptor_request, interceptor_response } from "./interceptors";
import createConfigObjectHandler from "./helpers/create-config-object-handler";
//
import type * as _Types from "./_model";
import type { _Auth } from "./auth";
import type { AxiosRequestConfig } from "axios";

// exports
export { AXIOS } from "./const";
export type { _Types as _Qunxios };
///

// TODO: try launching localtunnel into development when the first request is made :()
export function defineQunxios<
  JWT extends _Auth.JWTDecoded = _Auth.JWTDecoded,
  Token = any,
  TokenRevalidate = any,
  TokenRemove = any,
  /////
  CustomEventsForAuth extends Record<string, Function> = any
>(_options: _Types.Options<JWT, Token, TokenRevalidate, TokenRemove, CustomEventsForAuth>) {
  // init
  let __init_config: _Types.__InitConfig = {
    // defaults
    first_auth_request: true,
    httpOnlyCookieTempSession: false,
    access_denied: initAccessDenied(),
  };
  const options = checkOptions(_options as any);
  const _axios = createInstance(options);
  const _coh = createConfigObjectHandler({ options });

  // addons
  const auth = options.auth
    ? createAuthAddon({ options: options.auth, axios: _axios, _coh, __initConfig: __init_config })
    : undefined;

  // prepare
  options.triggers?.onInitAccessDenied?.(__init_config.access_denied);

  ////

  const interceptors_props: _Types._Interceptors.Props = {
    instance: _axios,
    options: options.interceptors,
    cycles: {
      auth: auth?.interceptorsCycle,
    },
    helpers: {
      getInitConfig: () => __init_config,
      updateInitConfig(props) {
        __init_config = { ...__init_config, ...props };
      },
    },
    _coh,
  };
  interceptor_request(interceptors_props);
  interceptor_response(interceptors_props);

  return {
    auth: auth?.user,
    ...auth?.api,
    get<T = any>(url: string, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.get<T>(url, config);
    },
    post<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.post<T>(url, data, config);
    },
    put<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.put<T>(url, data, config);
    },
    patch<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.patch<T>(url, data, config);
    },
    del<T = any>(url: string, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.delete<T>(url, config);
    },
  };
}

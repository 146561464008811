import { Guarded, route$navigation } from ":shared/components/route";
import { Route } from "@solidjs/router";
import { Show } from "solid-js";
//
import { checkUserToken, checkPlatformSettings } from "../SHARED/guards";
import { account$signout } from ":src/modules/account";
import { Loader1 } from ":src/components/loaders";
import { global } from ":global";
import { DashboardLayout } from ":src/layouts";

//
import routes from "./routes";
import { resources$, Resources } from ":src/modules/resources";
import { mentors$, Mentors } from ":src/modules/mentors";
import { participants$, Participants } from ":src/modules/participants";
import { exhibition$, Exhibition } from ":src/modules/exhibition";
import { project_submission$, ProjectSubmission, ProjectSubmissionTimer } from ":src/modules/project-submission";
import { livestage$, LiveStage } from ":src/modules/livestage";
import { schedule$agenda, Agenda } from ":src/modules/schedule";
import { help_center$, HelpCenter } from ":src/modules/help-center";
import { profile$, Profile } from ":src/modules/profile";
import { teams$my_team, MyTeam } from ":src/modules/teams";
import { workshops$, Workshops } from ":src/modules/workshops";

export default function Participant() {
  const baseRoute = routes.BASE;
  return (
    <Guarded
      // options={{ verbose: true }}
      setup={{
        baseRoute,
        guard: {
          steps: [checkUserToken, checkPlatformSettings],
        },
        lockedRoutes: {
          [routes.livestage]: () => global.store.actions.platform.getSettingsFor("live_stage").locked,
          [routes.project_submission]: () => global.store.actions.platform.getSettingsFor("submission").locked,
          [routes.workshops]: () => global.store.actions.platform.getSettingsFor("workshops").locked,
        },
      }}
      components={{
        pageNotFound: () => <div>this is Participant page error, this page doesn't exist</div>,
        layout(props) {
          const { guard } = props;
          return (
            <Show when={!guard.loading()} fallback={<Loader1 msg={guard.msg()} />}>
              <DashboardLayout
                childrenTop={[ProjectSubmissionTimer()]}
                sideNav={{
                  setup: {
                    baseRoute,
                  },
                  components: {
                    items: [
                      livestage$.Anchors.Side,
                      schedule$agenda.Anchors.Side,
                      resources$.Anchors.Side,
                      teams$my_team.Anchors.Side,
                      mentors$.Anchors.Side,
                      participants$.Anchors.Side,
                      exhibition$.Anchors.Side,
                      project_submission$.Anchors.Side,
                      workshops$.Anchors.Side,
                      //
                      () => route$navigation.Elements.Filler(),
                      () => route$navigation.Elements.Divider({ class: "bg#a" }),
                      //
                      help_center$.Anchors.Side,
                      profile$.Anchors.Side,
                      account$signout.Anchors.Side,
                    ],
                  },
                }}
              >
                <Show when={!guard.locked()} fallback={"This page is currently locked by admin"}>
                  {props.children}
                </Show>
              </DashboardLayout>
            </Show>
          );
        },
      }}
      events={{
        onRouteFirstLoad(trigger, { state }) {
          // trigger.redirect({ base: AuthConst.ROUTES.BASE });
          // console.log("paricipant first load :: ", state);
        },
        onRouteChange(trigger, { state }) {
          // console.log("paricipant route change :: ", state);
        },
      }}
    >
      <Route path={routes.help} component={HelpCenter} />
      <Route path={[routes.profile, "/"]} component={Profile} />
      {/*  */}
      <Route path={routes.livestage} component={LiveStage} />
      <Route path={routes.resources} component={Resources} />
      <Route path={routes.mentors} component={Mentors} />
      <Route path={routes.participants} component={Participants} />
      <Route path={routes.exhibition} component={Exhibition} />
      <Route path={routes.schedule} component={Agenda} />
      <Route path={routes.project_submission} component={ProjectSubmission} />
      <Route path={routes.myteam} component={MyTeam} />
      <Route path={routes.workshops} component={Workshops} />
    </Guarded>
  );
}

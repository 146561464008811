import { jwtDecode, type JwtPayload } from "jwt-decode";
import { checkAuthConfig } from "./check-auth-config";
//
import type { AxiosResponse } from "axios";
import type { __ConfigObjectHandler, __InitConfig } from "../_model";
import type { Options, JWTDecoded, __EncodedToken, __TokenHelpers, InvalidTokenStates } from "./_model";
//

type __TokenMethods = {
  getEncodedToken: Options["events"]["getEncodedToken"];
  onTokenDecoded: Options["triggers"]["onTokenDecoded"];
};

// list of popular jwt expiration object names to be decoded, make this an option for the user
const EXPIRATION_KEYS = ["exp", "expires", "expiresIn", "expiration"];
function checkEncoded(encoded: __EncodedToken) {
  let result = encoded;
  let err = undefined as string;

  if (result == undefined || typeof result === "undefined") {
    err = "token encoded can't be null";
  } else if (typeof result !== "object") {
    err = "token encoded must be an object";
  } else if (!("access" in result) || !("refresh" in result)) {
    err = "token encoded must be an object and must contain access and refresh keys!!!";
  } else if (result.access == undefined) {
    err = "token encoded can't have a null token.access";
  } else if (result.refresh == undefined) {
    err = "token encoded can't have a null token.refresh";
  } else if (typeof result.access !== "string" || typeof result.refresh !== "string") {
    err = "token encoded must set access and refresh to string";
    // console.error("AxiosInstance: token must be a string, i got :: token_response :: ", res, " :: token :: ", token);
    // return undefined;
  }

  return err;
}

function checkDecoded(decoded: any) {
  let result = decoded;
  let err = undefined as string;

  if (result == undefined || typeof result === "undefined") {
    err = "token retrieved from getEncodedToken can't be null";
  } else if (typeof result !== "object") {
    err = "token retrieved from getEncodedToken must be an object";
  }

  return err;
}

function decode<D>(props: {
  resOrToken: AxiosResponse | __EncodedToken;
  helpers: __TokenHelpers;
  _methods: __TokenMethods;
  __internal?: boolean;
}) {
  const { resOrToken, _methods, helpers, __internal } = props;
  let result: __EncodedToken = undefined;
  let res: AxiosResponse = undefined;
  try {
    if (resOrToken == undefined) {
      throw new Error("the passed resOrToken object is null");
    } else if ("data" in resOrToken) {
      // determine if passed token needs user help to determine where exactly are the access and refresh keys are.
      res = resOrToken;
      result = _methods?.getEncodedToken?.({ res: resOrToken });
    } else {
      result = resOrToken;
    }

    let err: string = checkEncoded(result);
    if (err != undefined) {
      console.error("Qunxios: token decode :: ", err);
      throw new Error("the passed encoded token can't be undefined!");
    }

    // everything is good, proceed to next process.
    const decoded = jwtDecode(result.access);
    if (__internal) {
      _methods?.onTokenDecoded?.({
        encoded: result,
        decoded,
        res,
        helpers,
      });
    }

    return {
      encoded: result,
      decoded: decoded as D,
    };
  } catch (err: any) {
    // console.error("Qunxios: token decode error :: ", err);
    throw err;
  }
}

export default function createTokenHandler<D extends JWTDecoded>(props: {
  options?: Options;
  _coh: __ConfigObjectHandler;
  __initConfig?: __InitConfig;
}) {
  const { options, __initConfig } = props;
  let _encoded_token: __EncodedToken = undefined;
  let _decoded_token: D = undefined;
  let err = undefined as string;

  // setup
  const _keys = {
    token_expiration: options?.defaults?.expirationKey,
    token_localstorage: options?.defaults?.localstorageTokenKey,
  };
  const _helpers: __TokenHelpers = {
    clear(props) {
      let { res, err } = props;
      if (!this.exists() && !err) {
        // err = "unknown token clear reason";
      }
      if (!props?.skipUpdate) {
        options?.triggers?.onTokenRemove?.({
          encoded: _encoded_token,
          decoded: _decoded_token,
          res,
          err,
          helpers: _helpers,
        });
      }
      _encoded_token = undefined;
      _decoded_token = undefined;

      switch (options.mode) {
        case "localstorage":
          if (__initConfig.access_denied?.localstorageDenied) {
            // console.warn("")
            return;
          }
          localStorage?.removeItem?.(_keys.token_localstorage);
          break;
      }
      // if (options?.manualTokenExpiration) {
      //   localStorage?.removeItem?.(KEYS.LOCALSTORAGE_TOKEN_MANUAL_EXPIRE);
      // }
    },
    exists() {
      return _decoded_token != undefined && typeof _decoded_token !== "undefined";
    },
    expired(props): boolean {
      if (!props) {
        props = {};
      }
      const { method } = props;
      let token = props?.tokenOrExp ?? _decoded_token;

      // check if token object exists
      if (token == undefined) {
        return true;
      } else if (typeof token !== "object" && typeof token !== "number") {
        console.error("Qunxios: JWT token is not of type object or number.");
        return true;
      }

      let exp = void 0;
      if (typeof token === "number") {
        exp = token;
      } else {
        exp = this.getExpiration();
      }

      // we found the expiration of the token
      if (exp != undefined) {
        if (typeof exp !== "number") {
          console.error("Qunxios: token exp is not a number :: ", exp);
          return true;
        }

        // use our method for calculating token expiration
        let time = Date.now(); /// 1000;
        if (method === "getTime") {
          time = new Date().getTime();
        }

        // console.log("exp is :: ", exp);
        const expired = time >= exp * 1000;
        return expired;
      }

      return true;
    },
    getExpiration(props) {
      if (props == undefined) {
        props = { decoded: _decoded_token, expKey: _keys.token_expiration };
      }
      let { decoded, expKey } = props;
      if (typeof decoded == undefined) {
        decoded = _decoded_token;
      }
      if (typeof expKey == undefined) {
        expKey = _keys.token_expiration;
      }
      // const err_encode = checkEncoded(_encoded_token)
      const err_decode = checkDecoded(decoded);
      if (err_decode) {
        console.warn("Qunxios: cannot get expiration key, decoded token doesn't exist!!");
        return undefined;
      }

      let result: number = undefined;
      if (typeof expKey === "string") {
        result = decoded?.[expKey];
        if (result == undefined) {
          console.warn(`Qunxios: value of expiration key <${expKey}> is undefined!`);
        }
      } else {
        console.warn("Qunxios: expiration key wasn't provided, searching for one!");
        for (const key of EXPIRATION_KEYS) {
          const val = decoded[key];
          if (val != undefined) {
            result = val;
            if (result == undefined) {
              console.warn(`Qunxios: value of expiration key <${key}> is undefined!`);
            }
            break;
          }
        }
      }

      if (result == undefined) {
        return undefined;
      }
      if (typeof result !== "number") {
        try {
          result = parseInt(result);
        } catch (err: any) {
          console.error("Qunxios: expiration of decoded token must be a number!!");
        }
      }
      return result;
    },
  };
  const _methods: __TokenMethods = {
    getEncodedToken: options?.events?.getEncodedToken,
    onTokenDecoded: options?.triggers?.onTokenDecoded,
  };

  // checks
  // TODO: check if the _methods and other necassary things exist

  // init
  switch (options?.mode) {
    case "localstorage":
      if (__initConfig?.access_denied?.localstorageDenied) {
        err = "localstorage access denied, can't init token";
        break;
      }
      const item = localStorage?.getItem?.(_keys.token_localstorage);
      if (item !== undefined && item !== null && item !== "undefined") {
        _encoded_token = JSON.parse(item);
      }
      break;
    default:
      break;
  }

  // if (options?.manualTokenExpiration) {
  //   const item = localStorage.getItem(KEYS.LOCALSTORAGE_TOKEN_MANUAL_EXPIRE);
  //   if (item && item !== "undefined") {
  //     const date_string = JSON.parse(item);
  //     const date = new Date(date_string);
  //     __initConfig.manualTokenExpire = date;
  //   }
  // }
  //

  // !! turn the following logic to a function on the object below !!
  if (err) {
    console.error("Qunxios: create token handler :: ", err);
  } else if (_encoded_token == undefined) {
    // console.warn("Qunxios: create token handler :: unreported error!!");
  } else {
    try {
      const token = decode<D>({
        resOrToken: _encoded_token,
        helpers: _helpers,
        _methods,
        __internal: true,
      });
      if (typeof token === "string") {
        throw token;
      } else {
        if (token?.decoded) {
          _decoded_token = token?.decoded;
          options?.triggers?.onTokenInitialLoad?.({
            encoded: _encoded_token,
            decoded: _decoded_token,
            helpers: _helpers,
          });
        }
      }
    } catch (err) {
      console.warn("Qunxios: initial load of token was invalid :: ", err);
    }
  }

  return {
    get getEncoded() {
      return () => _encoded_token;
    },
    get getDecoded() {
      return () => _decoded_token;
    },
    get helpers() {
      return _helpers;
    },
    // get decode() {
    //   return decode
    // },
    get save() {
      return (props: { encoded: __EncodedToken; decoded: D; res?: AxiosResponse; temp?: true }) => {
        const { encoded, decoded, res, temp } = props;
        // const err_encoded = checkEncoded(encoded)
        const err_decoded = checkDecoded(decoded);

        // check if decoded is valid object
        if (err_decoded) {
          console.warn("Qunxios: cannot save null decoded token, aborting save!");
          return;
        }

        const token_exists = _helpers.exists();
        options?.triggers?.onTokenSaved?.({
          encoded,
          decoded,
          res,
          shouldLogin: token_exists ? false : true, // if the token exists previously, then user shouldn't login
          helpers: _helpers,
          _encoded: _encoded_token,
          _decoded: _decoded_token,
        });

        _encoded_token = encoded;
        _decoded_token = decoded;

        switch (options?.mode) {
          case "http-only-cookie":
            if (temp === true) {
              console.warn("Qunxios: activating session-only token under http-only-cookie protocol");
              return;
            }
            break;
          case "localstorage":
            if (temp === true) {
              console.warn("Qunxios: activating localstorage temp session token");
              return;
            }
            if (__initConfig?.access_denied?.localstorageDenied) {
              console.warn("Qunxios: localstorage was blocked by user, using one time session");
              __initConfig.httpOnlyCookieTempSession = true;
              return;
            }

            localStorage?.setItem?.(_keys.token_localstorage, JSON.stringify(_encoded_token));
            break;
          default:
            break;
        }

        // if (options?.manualTokenExpiration) {
        //   const unit = options?.manualTokenExpiration.unit;
        //   const period = options?.manualTokenExpiration.period;
        //   const new_date = date.getDateFrom(new Date(), unit, period);
        //   localStorage.setItem(KEYS.LOCALSTORAGE_TOKEN_MANUAL_EXPIRE, JSON.stringify(new_date));
        //   __initConfig.manualTokenExpire = new_date;
        // }
      };
    },
    decodeAndSave(props: { resOrToken: AxiosResponse | __EncodedToken }): InvalidTokenStates | void {
      const { resOrToken } = props;
      try {
        // add status of called or fetched api type like token and token refresh
        // and pass it to the following decode and save functions
        const token = decode<D>({ resOrToken, _methods, helpers: _helpers, __internal: true });
        this.save({
          encoded: token?.encoded,
          decoded: token?.decoded,
          res: "data" in resOrToken ? resOrToken : undefined,
        });
      } catch (err: any) {
        console.error("Qunxios: token decodeAndSave error \n ", err);
        return options.defaults.invalidTokenDecode;
      }
    },
  };
}

import { Show, batch, createEffect, onCleanup, onMount, splitProps, untrack, ErrorBoundary } from "solid-js";
import type { AGuardTriggerProps, AGuardState, MainProps, AGuardHelperProps, AGuardLockedRoute } from "./_model";
import { Route, type Location, useLocation } from "@solidjs/router";
import { checkGuardSteps } from "./internal";
import { _route_actions, actions } from "../SHARED/store";
import { isMatch, isWildcard, removeTrailingSlash, matcher } from ":shared/helpers/methods";
import { solidstate } from ":shared/utils/state-manager";
import { mergeRouteHref, populateRouteHref } from "../SHARED/helpers";
import { getOwner } from ":shared/helpers/solid";

let app_first_loaded = true;
let prev_location: Location<unknown> = undefined;
/** record to keep track of which bases are ignored when other bases are loaded and/or match base pattern */
const route_record: Record<string, string[]> = {
  // "/auth": ["/auth*"],
  // "/admin": ["/admin*"],
};
export default function Guarded(props: MainProps) {
  const [local] = splitProps(props, ["children", "setup", "components", "events", "options"]);
  const owner_details = getOwner();
  if (isWildcard(local.setup?.baseRoute)) {
    throw new Error(
      `guarded: route must not include any wildcards -> ${local.setup?.baseRoute} please check your setup and change it!`
    );
  } else if (route_record[local.setup?.baseRoute]) {
    if (local.options?.verbose) {
      console.warn(
        "guarded: route base already exist, updating record for/with component -> ",
        owner_details?.tree?.[0],
        " :: ",
        route_record
      );
    }
  }

  const $show_route = solidstate.create(false);
  const $show_route_children = solidstate.create(false);
  const $lock_route = solidstate.create(undefined as AGuardLockedRoute);
  const $loader_msg = solidstate.create(undefined as string);

  let first_load = true;
  let navigated = false;

  route_record[local.setup?.baseRoute] = [`${removeTrailingSlash(local.setup?.baseRoute)}*`];

  if (local.options?.verbose) {
    console.log("guarded: setup abstract route :: ", local.setup?.baseRoute, " :: ", route_record);
  }

  onMount(() => {
    for (const record_base in route_record) {
      if (local.setup?.baseRoute === record_base) {
        continue;
      }
      const other_base_pattern = route_record[record_base][0];
      const local_base_pattern = route_record[local.setup?.baseRoute][0];
      if (isMatch(other_base_pattern, local_base_pattern)) {
        // console.log("base pattern :: ", local.setup?.baseRoute, " :: includes :: ", local_base_pattern, " :: ", other_base_pattern);
        route_record[local.setup?.baseRoute].push(`!${other_base_pattern}`);
      }
    }
    if (local.options?.verbose) {
      console.log(
        "guarded: mounted abstract route :: ",
        local.setup?.baseRoute,
        " :: with matche rules :: ",
        route_record[local.setup?.baseRoute]
      );
    }
  });

  onCleanup(() => {
    /**
     * ! this affects developer experience only, not production.
     * the order to after the initial setup is
     * setup all functions -> run on mount for all functions -> run effects for all functions
     * but after on clean up is ran it goes
     * run clean up per function -> run setup per function -> run on mount per function -> run setup per functions -> run effect per function
     * which in no way allows us to recapture all bases for route_record to setup matching patterns for this base.
     * so in short, developer needs to refresh page manually.
     */
    // route_record[local.setup?.baseRoute] = undefined;
    // $load_route.set(false);
    // unloadRoute();
    // console.log("cleaned abstract route :: ", local.setup?.baseRoute);
  });

  function loadRoute(props: { $location: Location<unknown>; triggers: AGuardTriggerProps }) {
    const { $location, triggers } = props;
    batch(() => {
      console.log(
        `%c ROUTE LOADED ${$location.pathname} `,
        "color: white;font-weight:bold; background-color: green;font-size: 15px;margin-top: 10px; margin-bottom: 10px; padding-top: 5px;padding-bottom: 5px;"
      );
      // first remove old route
      if (prev_location != undefined) {
        if (local.options?.verbose) {
          console.log("guarded: cleaning up route :: ", prev_location.pathname);
        }
        // figure out the flow and model of how developer can facilitate and/or make use of this
        local.events?.onRouteCleanup?.(triggers, {
          location: {
            current: $location,
            previous: prev_location,
          },
        });
      }

      prev_location = { ...$location };

      // if childrden are not shown, show them!
      if ($show_route_children.unwrap != undefined) {
        $show_route_children.set(true);
      }

      // find out if any routes are blocked
      const matches = matcher($location?.pathname, route_record[local.setup?.baseRoute]);
      const subroute =
        local.setup?.baseRoute.length <= 1 ? matches[0] : matches[0]?.substring(local.setup?.baseRoute.length);
      const route_locked = $lock_route.unwrap;
      // console.log("subroute for :: ", local.setup.baseRoute, " :: ", subroute);
      if (
        typeof local?.setup?.lockedRoutes?.[subroute] === "function" &&
        local?.setup?.lockedRoutes?.[subroute]?.() === true
      ) {
        if (route_locked != undefined) {
          $lock_route.set({ subroute });
        }
      } else {
        if (route_locked != undefined) {
          $lock_route.set(undefined);
        }
      }

      if (local.options?.verbose) {
        console.log(
          "guarded: show route base :: ",
          local.setup?.baseRoute,
          " :: exactly :: ",
          $location.pathname,
          // window.location.pathname,
          " :: $loader_msg :: ",
          $loader_msg.unwrap,
          " :: $show_route_children :: ",
          $show_route_children.unwrap
        );
      }
    });
  }

  return (
    <Route
      path={local.setup?.baseRoute}
      component={(component_props) => {
        const $location = useLocation();
        const $navigate = actions.navigateHref;
        const triggers: AGuardTriggerProps = {
          loadRoute: () => loadRoute({ $location, triggers }),
          navigate: (props) => {
            const nav_props: Exclude<typeof props, string> = {
              path: typeof props === "string" ? props : props.path ?? "",
              base: typeof props === "string" ? local.setup?.baseRoute : props.base ?? local.setup?.baseRoute,
            };
            const error = $navigate(nav_props);
            // console.log("navigation error :: ", error, " :: for :: ", nav_props);
            if (error == undefined) {
              navigated = true;
            }
            return error;
          },
          redirect(props) {
            // loadRoute();
            // $loader_msg.set(undefined);
            // $guard_passed.set(true);
            return this.navigate(props);
          },
        };

        onMount(() => {
          if (local.options?.verbose) {
            console.log("guarded: on mount of base route :: ", local.setup?.baseRoute);
          }
          untrack(() => {
            $show_route.set(true);
            $show_route_children.set(false);
            $lock_route.set(undefined);
            $loader_msg.set(undefined);
          });
        });

        // !! occurs when the base route is about to be removed !!
        onCleanup(() => {
          if (local.options?.verbose) {
            console.log("guarded: on unmount of base route :: ", local.setup?.baseRoute);
          }
          batch(() => {
            local.events?.onBaseRouteCleanup?.(triggers, {
              location: {
                current: $location,
                previous: prev_location,
              },
            });
          });
        });

        createEffect(async () => {
          const { pathname } = $location;

          if (local.options?.verbose) {
            console.log(
              "guarded: running route effect for :: ",
              local.setup?.baseRoute,
              " :: pathname -> ",
              pathname,
              " :: match routes -> ",
              JSON.stringify(route_record[local.setup?.baseRoute])
            );
          }

          untrack(async () => {
            const state: AGuardState =
              app_first_loaded === true ? "app_init" : first_load === true ? "first_load" : "each_route";
            const helpers: AGuardHelperProps = {
              state,
              base: local.setup?.baseRoute,
              isBaseRoute: $location.pathname === local.setup?.baseRoute,
              location: {
                current: $location,
                previous: prev_location,
              },
              routeMatchBase: (base) => {
                return isMatch($location.pathname, base + "*");
              },
              routeMatch: (route, pathname?: string) => {
                if (route === local.setup?.baseRoute) {
                  console.error(
                    "guarded: use of base route as path in routeMatch is prohibited, please use isBaseRoute instead."
                  );
                  return false;
                }
                const merged_href = mergeRouteHref({ base: local.setup?.baseRoute }, route);
                const full_path = populateRouteHref(merged_href);
                const current_route_is_subset_of_full_path = isMatch(pathname ?? $location.pathname, full_path + "*");
                // console.log(
                //   "route match current route is subset ::",
                //   full_path,
                //   " :: ",
                //   current_route_is_subset_of_full_path
                // );

                return current_route_is_subset_of_full_path;
              },
              routeMatchPathname: (path) => {
                let result: boolean = isMatch(path, $location.pathname + "*");
                // console.log("route match pathname ::", route_record[local.setup?.baseRoute], " :: base to r -> ", result);
                return result;
              },
            };

            first_load = false;
            navigated = false;
            app_first_loaded = false;
            _route_actions.setGlobalBaseFromGuard(local.setup?.baseRoute);

            if (local.options?.verbose) {
              console.log(
                "guarded: check route guards for :: ",
                local.setup?.baseRoute,
                " :: exactly :: ",
                $location.pathname,
                " :: state :: ",
                state,
                " :: $loader_msg :: ",
                $loader_msg.unwrap,
                " :: $show_route_children :: ",
                $show_route_children.unwrap
              );
            }

            if (state === "each_route") {
              local.events?.onRouteChange?.(triggers, helpers);
            } else {
              local.events?.onRouteFirstLoad?.(triggers, helpers);
            }

            const checks = await checkGuardSteps({
              steps: local.setup?.guard?.steps,
              events: {
                next: (step) => {
                  $loader_msg.set(step.loaderMsg);
                  return helpers;
                },
                onGuardFailureSkip: local.events?.onGuardFailureSkip,
              },
            });
            $loader_msg.set(undefined);
            //
            if (checks) {
              const { success, fail } = checks;

              if (success) {
                // console.log("surely success !!");
                if (local.events?.onGuardSuccess == undefined) {
                  loadRoute({ $location, triggers });
                } else {
                  local.events?.onGuardSuccess?.(triggers, helpers);
                }
                let error_msg = _route_actions.getGobalRouteError();
                if (!error_msg) {
                  if (!navigated && !$show_route_children.unwrap) {
                    error_msg = `Guarded: trigger.loadRoute haven't been called from within ${local.setup?.baseRoute} base route onGuardSuccess`;
                  }
                }
                if (error_msg) {
                  console.warn(error_msg);
                  $loader_msg.set(error_msg);
                }
              } else {
                const { error, step, stepEquals, key } = fail;
                let error_msg = undefined;
                let step_error = `Guarded: step error -> ${error?.message ?? error?.error?.message ?? "unspecified"}`;
                if (!local.events?.onGuardFailure) {
                  error_msg = step_error;
                  error_msg += `\n this is unhandled guard error in route base <${local.setup?.baseRoute}>`;
                  error_msg += `\n this can be handled by defining onGuardFailure under events prop`;
                } else {
                  local.events?.onGuardFailure?.(triggers, { ...fail, ...helpers });
                  if (_route_actions.getGobalRouteError()) {
                    // console.log("route failure ::: ", $guard_passed.unwrap);
                    error_msg = _route_actions.getGobalRouteError();
                  } else if (!navigated && !$show_route_children.unwrap) {
                    error_msg = step_error;
                    error_msg += `\n this is unhandled guard error in route base <${local.setup?.baseRoute}> with step index -> ${key}`;
                    error_msg += `\n you may have not called trigger.loadRoute or you've nvaigated to the same current route`;
                  }
                }
                if (local.setup.guard?.byPassErrors) {
                  loadRoute({ $location, triggers });
                  return;
                }
                if ($show_route_children.unwrap) {
                  $show_route_children.set(false);
                }
                if (error_msg != undefined) {
                  console.warn(error_msg);
                  if (error != undefined) {
                    console.error(error);
                  }
                  $loader_msg.set(error_msg);
                }
              }
            }
          });
        });

        return (
          <ErrorBoundary
            fallback={
              local.components?.layoutErrorBoundary ??
              ((err, reset) => {
                console.error(
                  "Guarded: FATAL :: an eror has been thrown from the layout of route base -> ",
                  local.setup.baseRoute,
                  "\nException: ",
                  err
                );
                return (
                  <div class="flex flex-col w-full h-full">
                    <span>an error exception has been thrown from layout of route base {local.setup?.baseRoute}</span>
                    <span>{err}</span>
                    <span onclick={() => reset()}>reset?</span>
                  </div>
                );
              })
            }
          >
            {/* first we show the layout of the route */}
            <Show when={$show_route.value}>
              {local.components?.layout?.({
                ...component_props,
                guard: {
                  loading: () => !$show_route_children.value,
                  msg: () => $loader_msg.value,
                  locked: () => $lock_route.value,
                },
              }) ?? component_props.children}
            </Show>
          </ErrorBoundary>
        );
      }}
    >
      <Route
        path="*"
        component={(component_props) => {
          return (
            <Show when={$show_route_children.value}>
              <ErrorBoundary
                fallback={
                  local.components?.errorBoundary ??
                  ((err, reset) => {
                    console.error(
                      "Guarded: FATAL :: an eror has been thrown from the setup of children components but never handled! in route base -> ",
                      local.setup?.baseRoute,
                      "\nException: ",
                      err
                    );
                    return (
                      <div class="flex flex-col w-full h-full">
                        <span>
                          an error exception has been thrown from children of route base {local.setup?.baseRoute}
                        </span>
                        <span>{err}</span>
                        <span onclick={() => reset()}>reset?</span>
                      </div>
                    );
                  })
                }
              >
                {component_props.children}
              </ErrorBoundary>
            </Show>
          );
        }}
      >
        {typeof local.children === "function" ? local.children() : local.children}
        <Route
          path="*"
          component={() => {
            return (
              <Show when={local.components?.pageNotFound == undefined} fallback={local.components?.pageNotFound?.()}>
                <div>This page doesn't exist</div>
              </Show>
            );
          }}
        />
      </Route>
    </Route>
  );
}

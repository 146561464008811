import { solidstate } from ":shared/utils/state-manager";
import { Show } from "solid-js";
import type { EvaluationModal } from "./_model";
import { URLViewer } from "./url-viewer";
import type { EvaluationData, Team } from "../_model";

export function Information(props: { entity: Team }) {
  const url = solidstate.create(undefined as EvaluationModal.AUrl);
  const team_info_keys: (keyof Team)[] = [
    "problem_statement",
    "applicable_solution",
    "impact",
    "business_model",
    "feasibility",
    "ideation",
    "research_process",
  ];

  function showURL(e: Event, _url: typeof url.value) {
    e.preventDefault();
    if (_url.type === "link") {
      let link = _url.url; //"https://www.youtube.com/watch?v=yvNFZhEruUg";
      if (link.includes("youtube")) {
        if (link.includes("/shorts")) {
          window.open(link, "_newtab");
          return;
        } else if (!link.includes("embed")) {
          link = link.replace("watch?v=", "embed/");
          const idx = link.indexOf("&");
          if (idx >= 0) {
            link = link.slice(0, idx);
          }
        }
      }
      console.log("url link :: ", link);
      _url.url = link;
    }
    url.set(_url);
    // form_transit.set(false);
  }
  return (
    <Show when={url.value == undefined} fallback={<URLViewer url={url.value} onExit={() => url.set(undefined)} />}>
      <div
        class={`flex flex-col  w-full h-full  text-0.6rem overflow-y-auto overflow-x-hidden  scrollbar$ bg-white transition-all duration-300 ease-in-out px-2 pt-2 !pb-4`}
      >
        <div
          class={`
          grid grid-cols-3 !w-full px2 h-fit gap-y-2 gap-x-5
          children:(flex flex-row w-full h-fit text-0.7rem items-center)
          lg:children:(space-x-2)
          <sm:(grid-cols-2)
          <xs:(grid-cols-1 px-1)
          <lg:children:(flex-col mx-2 items-start gap-y-1)
        `}
        >
          <div>
            <span class="text#a font-bold break-all">Team {props.entity.id}</span>
            <span>{props.entity?.team_name || "not specified"}</span>
          </div>
          <div>
            <span class="text#a font-bold break-all">Project Name</span>
            <span>{props.entity?.project_name || "not specified"}</span>
          </div>
          <div>
            <span class="text#a font-bold break-all">Track</span>
            <span>{props.entity?.track || "not specified"}</span>
          </div>
          <div>
            <p class="text#a font-bold break-all">Presentation</p>
            <button
              disabled={!props.entity?.presentation}
              onClick={(e) => {
                showURL(e, {
                  url: props.entity?.presentation,
                  type: "pdf",
                });
              }}
              class=" flex flex-col w-fit h-fit rounded-1 bg!a text!page disabled:(bg!skeleton text!paget) px-2 py-1 whitespace-nowrap overflow-hidden"
            >
              {!props.entity?.presentation ? "Not Provided" : "View"}
            </button>
          </div>
          <div>
            <p class="text#a font-bold break-all">Image</p>
            <button
              disabled={!props.entity?.project_image}
              onClick={(e) => {
                showURL(e, {
                  url: props.entity?.project_image,
                  type: "image",
                });
              }}
              class=" flex flex-col w-fit h-fit rounded-1 bg!a text!page disabled:(bg!skeleton  text!paget) px-2 py-1 whitespace-nowrap overflow-hidden"
            >
              {!props.entity?.project_image ? "Not Provided" : "View"}
            </button>
          </div>
          <div>
            <p class="text#a font-bold break-all">Video</p>
            <button
              disabled={!props.entity.project_video}
              onClick={(e) => {
                showURL(e, {
                  url: props.entity.project_video,
                  type: "link",
                });
              }}
              class=" flex flex-col w-fit h-fit rounded-1 bg!a text!page disabled:(bg!skeleton  text!paget) px-2 py-1 whitespace-nowrap overflow-hidden"
            >
              {!props.entity.project_video ? "Not Provided" : "View"}
            </button>
          </div>
        </div>
        <div class="flex flex-col w-full h-full">
          {team_info_keys.map((ti, i) => {
            const title = String(!ti ? "Untitled Question" : ti.replaceAll("_", " "));
            const value = props.entity?.[ti];
            return (
              <div class="flex flex-col !w-full items-start flex flex-col px3 pt2 pb4 mt2 h-fit space-y-2  text-0.65rem rounded-xl bg#skeleton text#paget">
                <p class="text#a capitalize">{title}</p>
                <p class={`${!value && "opacity-50"}`}>{String(value || "unanswered")}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Show>
  );
}

import { batch, createMemo, createRoot, getOwner, untrack } from "solid-js";
import { type SetStoreFunction, createStore, unwrap } from "solid-js/store";
import { type StateEventsAsyncInstance, createEventsAsync } from "./create-events-async";
import clone from "clone";
// import { getNewValue } from "./get-new-value";

export type StateAsyncFetcherRefetch<Args extends unknown[]> = unknown[] extends Args ? never[] : Args;
export type StateAsyncFetcher<T, Args extends unknown[], G extends Args> =
  | (() => Promise<T>)
  | [(...args: Args) => Promise<T>, ...args: G];
export interface StateAsyncValueStates {
  error: any;
  state: {
    loading: boolean;
    refetch: boolean;
    success: boolean;
    failed: boolean;
  };
}
export interface StateAsyncValue<T> extends StateAsyncValueStates {
  value: T;
}
export type StateAsyncOptions<T, Args extends unknown[], G extends Args> = {
  name?: string;
  initialValue?: T;
  refetchProtocol?: "force";
  preventUpdateOptions?: true;
  clearOnRefetch?: true;
  manualFetch?: boolean | { startSuccess?: boolean };
  logSuccess?: string;
  logError?: string;
  // autoRefetchOn?: () => G;
  // initialFetch?: "uponFirstUse" | "immediate"
};

export function createAsyncGlobal<T, Args extends unknown[], G extends Args>(
  ...args: Parameters<typeof createAsync<T, Args, G>>
) {
  const func = () => createAsync<T, Args, G>(...args);
  return createRoot(func);
}

// FIXME: called twice on i.e: courses.edit-modules.index but is dfetching only once, so prevent this duplicate update of state on same value
export function createAsync<T, Args extends unknown[], G extends Args>(
  fetcher: StateAsyncFetcher<T, Args, G>,
  options?: StateAsyncOptions<T, Args, G>
) {
  const owner = getOwner();
  if (fetcher == undefined) {
    throw new Error("fetcher cannot be undefined under owner " + owner);
  }
  const has_init_value = options?.initialValue && true;
  const manual_fetch = !options?.manualFetch
    ? undefined
    : typeof options?.manualFetch === "boolean"
    ? { startSuccess: false }
    : options?.manualFetch;
  const [$value, $set] = createStore(
    {
      state: { loading: !manual_fetch, success: has_init_value ?? manual_fetch?.startSuccess },
      error: undefined,
      value: options?.initialValue,
    } as StateAsyncValue<T>,
    { name: options?.name }
  );
  const state = {
    hasBeenUsedBefore: false,
    valueUntracked: undefined as T,
    eventHandler: undefined as StateEventsAsyncInstance<T>,
    fetcher: (typeof fetcher === "function" ? fetcher : fetcher.shift()) as (...args: any) => Promise<T>,
    args: (typeof fetcher === "function" ? undefined : fetcher) as unknown as G,
    has_args: Array.isArray(fetcher) && fetcher !== undefined && fetcher !== null && fetcher?.length > 0,
  };

  if (!manual_fetch) {
    updateWithFetcher(
      $set,
      $value,
      !state.args ? state.fetcher?.() : state.fetcher?.(...state.args),
      options?.logSuccess,
      options?.logError
    );
  }

  return {
    // TODO: think of better naming for future projects
    get unwrap() {
      // unwrap means untrack
      return untrack(() => unwrap($value));
    },
    get clone() {
      return clone(unwrap($value.value));
    },
    get cloneUnwrapped() {
      return untrack(() => this.clone);
    },
    get value() {
      if (!state.hasBeenUsedBefore) {
        state.hasBeenUsedBefore = true;
      }
      return $value.value;
    },
    get state() {
      if (!state.hasBeenUsedBefore) {
        state.hasBeenUsedBefore = true;
      }
      return $value.state;
    },
    get error() {
      if (!state.hasBeenUsedBefore) {
        state.hasBeenUsedBefore = true;
      }
      return $value.error;
    },
    get set() {
      return (setter: Exclude<T, Function> | ((prev: T, helpers: { clone: typeof clone }) => T)) => {
        // @ts-ignore
        $set((s) => ({ ...s, value: typeof setter === "function" ? setter(s.value, { clone }) : setter }));
      };
    },
    get clear() {
      return () => {
        $set({ value: undefined, error: undefined });
      };
    },
    // non instigators
    owner,
    hasBeenUsedBefore: () => state.hasBeenUsedBefore,
    get on() {
      if (!state.eventHandler) {
        state.eventHandler = createEventsAsync($value, owner);
      }
      return state.eventHandler;
    },
    updateOptions(o: StateAsyncOptions<T, Args, G>) {
      if (options?.preventUpdateOptions) {
        console.error("create-async: options cannot be updated for -> ", owner);
        return;
      }
      options = { ...options, ...o, preventUpdateOptions: options?.preventUpdateOptions };
    },
    /** refetch promise with original args. */
    refetch() {
      untrack(() => {
        this.recall(...(state.has_args ? (state.args as any) : []));
      });
    },
    /** recall promise function with new args. */
    recall(...args: StateAsyncFetcherRefetch<Args>) {
      untrack(() => {
        // console.log("refetching with :: ", $value.state);
        if (!options?.refetchProtocol && $value.state.loading) {
          // console.log("preventing refetch :: ", state.value.state);
          return;
        }
        batch(() => {
          if (options?.clearOnRefetch) {
            this.clear();
          }
          $set("state", { loading: true, refetch: true, success: false, failed: false });
        });
        if (!state.has_args) {
          updateWithFetcher($set, $value, state.fetcher?.(), options?.logSuccess, options?.logError);
        } else {
          let args_to_pass = state.args;
          if (args && args.length > 0) {
            args_to_pass = args as any;
          }
          updateWithFetcher($set, $value, state.fetcher?.(...args_to_pass), options?.logSuccess, options?.logError);
        }
      });
    },
    derive<G>(fn: (s: T) => G) {
      return createMemo(() => {
        if (!this.state.success) {
          return undefined;
        }
        return fn(unwrap($value.value));
      });
    },
  };

  // TODO: add ability to listen to changes in original args instad of having dev need to do something like in /courses/routes/student-routes
  // if (owner && options?.autoRefetchOn) {
  //   runWithOwner(owner, () => {
  //     createEffect(() => {
  //       const args = options?.autoRefetchOn();
  //       console.log("state.fetcher", args);
  //       return state.args;
  //     });
  //   });
  // }
  // createRoot(() => {
  //   onMount(() => {
  //   });
  // }, owner);
}

async function updateWithFetcher<T, Args extends never[] = never[]>(
  set: SetStoreFunction<StateAsyncValue<T>>,
  state: StateAsyncValue<T>,
  // options: StateAsyncOptions<T>,
  res: Promise<T>,
  logSuccess: string,
  logError: string
) {
  res
    .then((d) => {
      if (logSuccess) {
        console.log(logSuccess);
      }
      // console.log("d is :: ", d);
      set((s) => ({
        value: d,
        error: undefined,
        state: { loading: false, refetch: false, failed: false, success: true },
      }));
    })
    .catch((e) => {
      if (logError) {
        console.log(logError);
      }
      set((s) => ({
        value: s.value,
        error: e,
        state: { loading: false, refetch: false, failed: true, success: false },
      }));
    });
}

/* 
      TODO: proof of concept, need to be expanded to include all deeply nested 
      object keys as well as heavily tested on regular primitive values
      TODO: move to independant file to be used across all states
      TODO: better type detection on 3rd level of nesting and onward, specifically for record objects
    */
// type Nested<T> = T extends object ? (T extends any[] ? number : keyof T) : never;
// type NestedObj<T> = T extends object ? (T extends any[] ? T[number] : keyof T) : T;
// nested(key: Nested<T>) {
//   if ($value.value == undefined) {
//     return undefined;
//   }
//   function nestedInternal<F>(item: F, trace: any[]) {
//     return {
//       trace,
//       depth: trace.length - 1,
//       get key() {
//         return trace[this.depth];
//       },
//       get value(): NestedObj<F> {
//         return item[this.key];
//       },
//       set value(val: any) {
//         const setters = [];
//         trace.forEach((k) => {
//           setters.push((f, i) => i === k);
//         });
//         setters.push(val);
//         // @ts-ignore
//         $set("value", ...(setters as any));
//       },
//       get delete() {
//         return () => {
//           if (item == undefined) {
//             return;
//           }
//           if (typeof this.key === "number") {
//             console.log("asdasd :: ");
//             const setters = [];
//             trace.forEach((k, n) => {
//               if (n + 1 < trace.length) {
//                 setters.push((f, i) => i === k);
//               }
//             });
//             setters.push((f, i) => {
//               f.splice(this.key, 1);
//               return [...f];
//             });
//             console.log("setters :: ", setters);
//             // @ts-ignore
//             $set("value", ...(setters as any));
//           }
//         };
//       },
//       get nested() {
//         return <G extends NestedObj<F>>(k: Nested<G>) => {
//           if (typeof this.value !== "object") {
//             console.error("create-async: reached maximum depth this tree can have ", this.depth, this.trace, owner);
//             return undefined;
//           }
//           const _item = unwrap(this.value[k as any]);
//           return nestedInternal<NestedObj<G>>(_item, [...trace, k]);
//         };
//       },
//     };
//   }
//   return nestedInternal<T>(this.value, [key]);
// },

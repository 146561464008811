import { defineQunxios } from ":shared/utils/qunxios";
import { default as store } from "./store";
import type { TokenPayload, TokenDecoded, TokenTypeKey, TokenType } from "./_model";
import { TOKEN_TYPE } from "./constants";
import { decodeError } from "./api-helpers/decode-errors";
import auth_routes from ":src/routes/auth/routes";

let token_key: TokenTypeKey = undefined;
function redirectToUserRouteBase(props: { decoded: any; clearToken: any; shouldLogin: boolean }) {
  const { decoded, clearToken, shouldLogin } = props;
  // console.log("token Obtained :: ", jwt, " :: ", res);
  if (!token_key) {
    store.actions.alert.pushToast({ type: "error", message: "unknown user type, redirecting!" });
    // store.actions.route.navigate(ROUTE_BASES.auth);
    return;
  } else if (token_key === "filterer") {
    store.actions.alert.pushToast({
      type: "error",
      message: "this phase has already passed!",
    });
    clearToken();
    return;
  }
  let base_route = TOKEN_TYPE?.[token_key]?.base_route;
  if (!base_route) {
    console.warn("base route is not defined for token type ", token_key);
    return;
  }
  if (shouldLogin) {
    console.log("should login :: ", shouldLogin);
    store.actions.route.navigateHref({ base: base_route });
    store.actions.alert.pushToast({ type: "success", message: `logged in as ${decoded.first_name}` });
  } else {
    store.actions.alert.pushToast({ type: "success", message: `welcome back, ${decoded.first_name}` });
  }
}

export default defineQunxios({
  baseUrl: import.meta.env.__API,
  triggers: {
    onInitAccessDenied: (access) => {
      if (access.localstorageDenied) {
        store.actions.route.navigateHref({ base: "/auth", path: "/login" });
      }
    },
  },
  interceptors: {
    processRespondsErrors({ err }) {
      const data = err?.response?.data;
      const is_doc_type = typeof data === "string" && data.startsWith("<!");
      let error_message = null; // decodeError(err, "unknown error");
      if (is_doc_type) {
        error_message = err.response?.statusText;
      } else {
        error_message = decodeError(err, "unknown error response");
      }
      // console.log("err is ", err);
      console.log("err error message is :: ", error_message);
      store.actions.alert.pushToast({
        type: "error",
        message: error_message ?? "an error occured",
      });
      return {
        message: error_message,
      };
    },
  },
  auth: {
    mode: "localstorage",

    // manulTokenExpiration: { unit: "s", period: 20 }, // use for testing only, haven't tested this in production
    defaults: {
      invalidToken: "keep token",
      invalidTokenDecode: "keep token",
      expirationKey: "exp",
    },

    endpoints: {
      postToken: {
        url: "/auth/token",
        schema: null as TokenPayload,
        jwt_schema: null as TokenDecoded,
      },
      postTokenRevalidate: { url: "/auth/token/refresh", schema: null as TokenPayload },
      // getTokenRevalidate: { url: "/auth/token/details/" },
      // getTokenRemove: { url: "auth/logout/" },
    },
    triggers: {
      onTokenDecoded({ decoded, res, helpers }) {
        // const d = new Date(0);
        // d.setUTCSeconds(jwt.exp);
        // const date = new Date();
        // const diff = d.getTime() - date.getTime();
        // console.log("onTokenDecoded :: ", jwt);
        const id = decoded.user_type;
        // let token_type = undefined;
        token_key = undefined;
        for (const key in TOKEN_TYPE) {
          const value = TOKEN_TYPE[key];
          if (value.id === id) {
            // token_type = value;
            token_key = key as TokenTypeKey;
            break;
          }
        }
        if (token_key == undefined) {
          throw new Error("TOKEN TYPE WITH ID DOESN'T EXIST :: " + id);
        }
      },
      // signin/token refreshed
      onTokenSaved({ decoded, res, helpers, shouldLogin }) {
        // console.log("token saved :: ", decoded, " :: ", res);
        // first login
        redirectToUserRouteBase({ decoded, clearToken: helpers.clear, shouldLogin });
      },
      onTokenRemove: ({ decoded, res, err }) => {
        // console.log("token removed :: ", jwt);
        if (decoded || err) {
          store.actions.route.navigateHref({ base: auth_routes.BASE, path: auth_routes.login });
        }
      },
      onInvalidatedToken({ defaults, __configState }) {
        // logout on invalid token upon token remove call
        if (__configState.is_token_revalidate) {
          return "clear token";
        }
        return defaults.invalidToken;
      },
      // onOneTimeSessionsActivated: () => {
      //   toast_group$actions.pushToast({
      //     type: "info",
      //     message:
      //       "One Time Session is activated due to disabled cookies. \nYoull stay connected so long you don't reload the page or exit browser!.",
      //   });
      // },
    },
    events: {
      getEncodedToken: ({ res }) => ({ access: res?.data?.access, refresh: res?.data?.refresh }),
      getTokenRefresh: ({ encoded }) => ({ refresh: encoded.refresh }),
    },
    customEvents: ({ getDecodedToken: getJWT, helpers }) => ({
      hasToken: () => {
        return helpers.exists();
      },
      getDecodedToken: () => {
        return getJWT();
      },
      getTokenKey() {
        if (!token_key) return undefined;
        return token_key;
      },
      getTokenObject<T extends TokenTypeKey>(key: T): TokenType[T] {
        return TOKEN_TYPE[key];
      },
      // redirectToUserRouteBase: () => {
      //   redirectToUserRouteBase({ decoded: getJWT(), clearToken: helpers.clear,  });
      // },
    }),
  },
});

import { For, Match, Show, Switch, createEffect, createMemo, createSignal, untrack } from "solid-js";
import type { MainProps } from "./_model";
import { solidstate } from ":shared/utils/state-manager";
import { get_user, update_user, update_profile_image, update_password } from "./apis";
import { PrimaryButton } from ":src/components/form";
import { updateProfileInputs, createPasswordResetInputs } from "./forms";
import { global } from ":global";
import { Loader2 } from ":src/components/loaders";
import person_img from "./assets/images/person_placeholder.png";

export default function Profile(props: MainProps) {
  const $user = solidstate.createAsync(get_user);
  const FormInputs = updateProfileInputs();
  const ResetPasswordInputs = createPasswordResetInputs();
  const $loading_password_update = solidstate.create(false);
  const $loading_image_update = solidstate.create(false);
  const $loading_info_update = solidstate.create(false);
  FormInputs.Email.control.markDisabled(true);
  FormInputs.Track.control.markDisabled(true);
  const char_limit = 300;
  const calculateCharCount = (inputField) => {
    const user_chars = inputField.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  };
  const $BioChars = createMemo(() => calculateCharCount(FormInputs.Bio));

  createEffect(() => {
    if ($user.state.success) {
      FormInputs.Actions.update($user.unwrap.value);
      // console.log("User:: ", FormInputs.Actions.getValues());
      untrack(() => {});
    }
  });

  const onUploadProfileImage = async () => {
    if (!!FormInputs.Image.control.value) {
      $loading_image_update.set(true);
      const formData = new FormData();
      const file = FormInputs.Image.control.value[0];
      formData.append("image", file);
      try {
        await update_profile_image(formData);
        global.store.actions.alert.pushToast({
          type: "success",
          message: "Profile Image Updated!",
        });
        $loading_image_update.set(false);
      } catch (error: any) {
        $loading_image_update.set(false);
      }
    } else {
      global.store.actions.alert.pushToast({
        type: "info",
        message: "Please click on the image and upload image first!",
      });
    }
  };

  const onPasswordUpdate = async (e: Event) => {
    e.preventDefault();
    $loading_password_update.set(true);
    const values = ResetPasswordInputs.Actions.getValuesSnakecase();
    const payload = {
      // password: "!!@@",
      password: values["password"],
    };

    try {
      await update_password(payload);
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Password Updated!",
      });
      $loading_password_update.set(false);
    } catch (error: any) {
      $loading_password_update.set(false);
    }
  };

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    $loading_info_update.set(true);
    const values = FormInputs.Actions.getValuesSnakecase();

    const formData = new FormData();

    for (const v in values) {
      if (v !== "image") {
        formData.append(v, values[v]);
      } else if (
        FormInputs.Image?.control?.value?.[0] !== undefined &&
        FormInputs.Image?.control?.value?.[0] !== null
      ) {
        formData.append("image", FormInputs.Image?.control?.value?.[0]);
      }
    }

    try {
      await update_user(formData);
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Profile Updated!",
      });
      $loading_info_update.set(false);
    } catch (error: any) {
      $loading_info_update.set(false);
    }
  };

  return (
    <section class="flex flex-col w-full min-h-full h-full overflow-y-auto overflow-x-hidden md:(px-5 py-3) <md:(px-3 py-2)">
      <Switch>
        <Match when={$user.state.loading}>
          <div class="flex w-full min-h-screen md:h-full items-center justify-center">
            <Loader2 msg="Loading User Details..." />
          </div>
        </Match>
        <Match when={$user.state.failed}>an error occurred while fetching {$user.error}</Match>
        <Match when={$user.state.success}>
          <h1 class="text-22px md:text-xl md:text-2xl font-[600] text-black pb-2">Profile Setting</h1>
          <div class="flex flex-col md:flex-row justify-start items-center gap-3 ">
            <section class="flex items-center gap-20px">
              <FormInputs.Image.InputFile
                accept="image/*"
                elements={{
                  input(props) {
                    return (
                      <div
                        class="flex flex-col justify-center items-center bg#orange! text-white w-100px h-100px absolute top-0 opacity-60 cursor-pointer"
                        onclick={props.onInputClicked}
                      >
                        <span class="text-16px">{props.drag ? "Drop Files" : "Edit"}</span>
                      </div>
                    );
                  },
                  input_text: ({ drag, file }) =>
                    !file ? (!drag ? "Edit" : "drop files") : drag ? "update file" : file.name,
                  viewer({ placeholder }) {
                    console.log("placeholder:: ", placeholder);
                    return (
                      <Show fallback={<img src={person_img} class="w-full h-full object-cover " />} when={placeholder}>
                        <img src={placeholder} class="w-full h-full object-cover " />
                      </Show>
                    );
                  },
                }}
                classes={{
                  container: "w-100px h-100px rounded-full border-dashed border-2 border-red",
                  input: "bg#orange! text-white opacity-60",
                }}
              />

              <div class="flex flex-col gap-20px"></div>
            </section>

            <div class="flex flex-col gap-3 max-w-50%">
              <p class="text#txtgray py-1 text-14px ">Recommended 500 x 500 px. JPG or PNG is allowed</p>

              <Show
                fallback={
                  <PrimaryButton label="Updating Avatar..." classes="w-fit px-4! py-[16px]! text-[14px]!" disabled />
                }
                when={!$loading_image_update.value}
              >
                <PrimaryButton
                  label="Update Avatar"
                  onClick={onUploadProfileImage}
                  classes="w-fit px-4! py-[16px]! text-[14px]!"
                />
              </Show>
            </div>
          </div>
          <p class="text#label-gray text-14px w-full px-2 py-3">User ID: {$user.unwrap.value?.id ?? "not found"} </p>
          {/* outer */}
          <form class="w-full flex flex-col justify-center">
            {/* first row: */}

            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50% py-2">
                <FormInputs.FirstName.Label title="First Name" class="form-label ">
                  <FormInputs.FirstName.Error class="form-input-error" />
                </FormInputs.FirstName.Label>

                <FormInputs.FirstName.Input
                  autocomplete="First Name"
                  type="text"
                  class="form-input"
                  placeholder={`First Name`}
                />
              </div>
              <div class="w-full md:w-50% py-2">
                <FormInputs.LastName.Label title="Last Name" class="form-label">
                  <FormInputs.LastName.Error class="form-input-error " />
                </FormInputs.LastName.Label>

                <FormInputs.LastName.Input
                  autocomplete="LastName"
                  type="text"
                  class="form-input"
                  placeholder={`Last Name`}
                />
              </div>
            </div>
            {/* Second row */}

            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50% py-2">
                <FormInputs.Email.Label title="Email" class="form-label">
                  <FormInputs.Email.Error class="form-input-error" />
                </FormInputs.Email.Label>

                <FormInputs.Email.Input autocomplete="Email" class="form-input" />
              </div>

              <div class="w-full md:w-50% py-2">
                <FormInputs.Track.Label title="Track" class="form-label">
                  <FormInputs.Track.Error class="form-input-error" />
                </FormInputs.Track.Label>

                <FormInputs.Track.Input autocomplete="Track" class="form-input " />
              </div>
            </div>

            {/* Third row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50% py-2">
                <FormInputs.Portfolio.Label title="Portfolio Link" class="form-label">
                  <FormInputs.Portfolio.Error class="form-input-error" />
                </FormInputs.Portfolio.Label>

                <FormInputs.Portfolio.Input
                  autocomplete="Portfolio Link"
                  type="text"
                  class="form-input"
                  placeholder={`https://...`}
                />
              </div>
              <div class="w-full md:w-50% py-2">
                <FormInputs.Major.Label title="Job Title" class="form-label">
                  <FormInputs.Major.Error class="form-input-error " />
                </FormInputs.Major.Label>

                <FormInputs.Major.Input class="form-input" />
              </div>
            </div>

            {/* Forth row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full py-2">
                <FormInputs.Bio.Label title="Bio" class="form-label">
                  <FormInputs.Bio.Error class="form-input-error" />
                </FormInputs.Bio.Label>

                <div class="relative">
                  <FormInputs.Bio.TextArea
                    autocomplete="Bio"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about yourself"
                    maxlength={char_limit}
                  />
                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$BioChars()}</div>
                </div>
              </div>
            </div>

            {/* submit btn */}
            <div class="flex justify-end mt-2">
              <Show
                fallback={
                  <PrimaryButton
                    label="Updating Data...  "
                    classes="mt-3! mb-2!  px-30px! !py-2   text-16px!"
                    disabled
                  />
                }
                when={!$loading_info_update.value}
              >
                <FormInputs.Actions.Button
                  class="disabled:bg#disabled text-white  flex items-center justify-center text-16px font-bold cursor-pointer bg#orange mt-3 mb-2  px-30px py-2 rounded-md"
                  onclick={(e) => {
                    onSubmit(e);
                  }}
                  statusValid={"Save Changes"}
                  statusInvalid={"Save Changes"}
                />
              </Show>
            </div>
          </form>

          <form class="w-full flex flex-col justify-center pb-10 <md:(pt-3)">
            {/* first row: */}
            <h1 class="text-20px md:text-xl md:text-2xl font-[600] text-black pb-2">Reset Password</h1>

            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50% py-2">
                <ResetPasswordInputs.Password.Label title="New Password" class="form-label">
                  <ResetPasswordInputs.Password.Error class="form-input-error" />
                </ResetPasswordInputs.Password.Label>

                <ResetPasswordInputs.Password.Input
                  autocomplete="New Password"
                  type="password"
                  class="form-input"
                  placeholder={`New Password`}
                />
              </div>
              <div class="w-full md:w-50% py-2">
                <ResetPasswordInputs.ConfirmPassword.Label title="Confirm Password" class="form-label">
                  <ResetPasswordInputs.ConfirmPassword.Error class="form-input-error" />
                </ResetPasswordInputs.ConfirmPassword.Label>

                <ResetPasswordInputs.ConfirmPassword.Input
                  autocomplete="Confirm New Password"
                  type="password"
                  class="form-input"
                  placeholder={`Confirm New Password`}
                />
              </div>
            </div>

            {/* submit btn */}
            <div class="flex justify-end">
              <Show
                fallback={
                  <PrimaryButton
                    label="Updating Password..."
                    classes="mt-3! mb-2!  px-30px!  !py-2 text-16px!"
                    disabled
                  />
                }
                when={!$loading_password_update.value}
              >
                <ResetPasswordInputs.Actions.Button
                  class="disabled:bg#disabled text-white  flex items-center justify-center text-16px font-bold cursor-pointer bg#orange mt-3 mb-2  px-30px py-2 rounded-md"
                  onclick={(e) => {
                    onPasswordUpdate(e);
                  }}
                  statusValid={"Update Password"}
                  statusInvalid={"Update Password"}
                />
              </Show>
            </div>
          </form>
        </Match>
      </Switch>
    </section>
  );
}

import type { MainProps } from "./_model";
import { Match, Show, Switch, batch, createMemo, splitProps } from "solid-js";
import { useLocation, useSearchParams } from "@solidjs/router";
import { _route_actions, actions } from "../SHARED/store";
import { populateRouteHref } from "../SHARED/helpers";

export default function Anchor(props: MainProps) {
  const [local, others] = splitProps(props, [
    "class",
    "setup",
    "events",
    "components",
    "triggers",
    "options",
    "children",
  ]);
  const [search_params, setParams] = useSearchParams();
  const $location = useLocation();
  let $href = createMemo(() => {
    if (local.setup?.to !== undefined && local.setup?.to !== null) {
      return populateRouteHref(local.setup?.to);
    }
    return undefined;
  });

  const $isActive = createMemo(() => {
    let result = false;
    const link_active = local.setup?.to && $location.pathname.includes($href());

    let search_params_active = false;
    if (local.options?.activeOnSearchParams) {
      let total = 0;
      let active = 0;
      for (const p in local.options?.activeOnSearchParams) {
        const value = String(local.options?.activeOnSearchParams?.[p]);
        if (search_params[p] === value) {
          active++;
        }
        total++;
      }
      if (active === total) {
        search_params_active = true;
      }
    }
    if (local.options?.activeOnBase) {
      if (_route_actions.isRouteBase($location.pathname)) {
        result = true;
      }
    }
    if (link_active || search_params_active) {
      result = true;
    }
    if (result) {
      local.triggers?.onActive?.();
    }
    return result;
  });

  return (
    <a
      {...others}
      href={undefined}
      // href={$href()}
      class={`relative cursor-pointer ${local.class ?? ""}`}
      onClick={() => {
        batch(() => {
          const should_navigate = local.events?.getShouldNavigate?.() ?? true;
          if (!should_navigate) {
            return;
          }
          console.log(
            `%c END OF ROUTE ${$location.pathname} `,
            "color: white;font-weight:bold; background-color: red;font-size: 15px;margin-top: 10px; margin-bottom: 10px; padding-top: 5px;padding-bottom: 5px;"
          );
          local.triggers?.onBeforeNavigate?.();
          if (local.setup?.to) {
            actions.navigateHref(local.setup?.to);
          }
          // console.warn("setting search params :: ", local.searchParams);
          if (local.setup?.searchParams) {
            setParams(local.setup?.searchParams, { replace: true });
          }
          local.triggers?.onAfterNavigate?.();
        });
      }}
      classList={{
        [local.setup?.classes?.active ?? "active"]: $isActive(),
      }}
    >
      <Show
        when={$isActive() && local.components?.activeIcon}
        fallback={
          typeof local.components?.idleIcon === "string" ? (
            <i class={`w-24px h-24px ${local.components?.idleIcon ?? ""}`} />
          ) : typeof local.components?.idleIcon === "function" ? (
            local.components?.idleIcon?.()
          ) : (
            local.components?.idleIcon
          )
        }
      >
        {typeof local.components?.activeIcon === "string" ? (
          <i class={`w-24px h-24px ${local.components?.activeIcon ?? ""}`} />
        ) : typeof local.components?.activeIcon === "function" ? (
          local.components?.activeIcon?.()
        ) : (
          local.components?.activeIcon
        )}
      </Show>
      <div class="title-group">
        <Show when={local.components?.title}>
          {typeof local.components?.title === "string" ? (
            <span class={`title`}>{local.components?.title}</span>
          ) : typeof local.components?.title === "function" ? (
            local.components?.title?.()
          ) : (
            local.components?.title
          )}
        </Show>
        <Show when={local.components?.subtitle}>
          {typeof local.components?.subtitle === "string" ? (
            <span class={`subtitle`}>{local.components?.subtitle}</span>
          ) : typeof local.components.subtitle === "function" ? (
            local.components?.subtitle?.()
          ) : (
            local.components?.subtitle
          )}
        </Show>
      </div>
      {local.children}
    </a>
  );
}

import { Anchor, type _Navigation } from ":shared/components/route";
import { global } from ":global";

export default function Livestage(props: _Navigation.SideNavJSXItemProps) {
  const $settings = global.store.hooks.platform.useSettings("live_stage");
  return (
    <div class="relative ">
      <Anchor
        class={`${props.anchor?.class ?? ""} border#pt border-2 border-solid`}
        setup={{
          // to: authRoutes.,
          classes: {
            active: `${props.anchor.setup.classes.active}`,
          },
        }}
        triggers={{
          onBeforeNavigate: () => {
            global.api.auth.logout();
          },
        }}
        components={{
          title: "Logout",
          idleIcon: <i class="i-ri:logout-box-line" />,
        }}
      />
      <div class={!$settings().locked && "aboslute top-0 bg#a"} />
    </div>
  );
}

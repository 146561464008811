import { solidstate } from ":shared/utils/state-manager";
import { Match, Show, Switch, createEffect, splitProps } from "solid-js";
import { createLoginInputs } from "./forms";
import { requestPasswordResetEmail } from "../password-reset/apis";
import type { MainProps } from "./_model";
import { global } from ":global";
import login_logo from "../SHARED/assets/images-hackyard-demo/logo.png?format=webp";
import BackArrow from "../SHARED/assets/icons/back-arrow.svg";

export default function Signin(props: MainProps) {
  const [local, others] = splitProps(props, ["onLoggedIn"]);
  const app_flavor = global.contexts.whitelabel.useAppFlavor();
  const app_meta = global.contexts.head_tag.useMeta();
  app_meta?.setTitle?.("Login");
  //
  const FormLogin = createLoginInputs("eslam_hd_part");
  const $reset_pass = solidstate.create(false);
  const $totp = solidstate.create(false);

  createEffect(() => {
    if ($reset_pass.value) {
      // FormLogin.Password.control.markRequired(false);
      FormLogin.Password.control.markDisabled(true);
    } else {
      FormLogin.Password.control.markDisabled(false);
    }
  });
  const onSubmit = async (e: Event) => {
    const values = FormLogin.Actions.getValuesLowercase();
    // console.log("login submit");
    if ($reset_pass.value) {
      await requestPasswordResetEmail(values.email).then((res) => {
        global.store.actions.alert.pushToast({
          type: "success",
          message: "confirmation email has been sent!",
        });
        console.log("Res:: ", res);
      });
      return;
    }
    await global.api.auth.login(values).then(() => {
      // $.actions.pushToast({ type: "success", message: "Logged in" });
      local?.onLoggedIn?.();
      // if (code) {
      // 	log.dev.info("code", code);
      // 	$totp.set(true);
      // } else {
      // }
    });

    return;
  };

  return (
    <section class="w-full h-screen bg-white mx-auto flex justify-items-start items-center relative overflow-y-hidden">
      <div class="w-full md:w-[40%] flex flex-col justify-start mx-10 gap-5 px-5 py-4 md:px-6 md:py-8 lg:py-0">
        <form class="flex flex-col w-full h-full">
          <div class="flex justify-start translate-x-[-1rem] ">
            <img class="" width={110} src={`${login_logo}`} alt="logo" />
          </div>
          <Show when={$reset_pass.value}>
            <span
              class="cursor-pointer text-16px p-0 pt-2 text-black flex justify-start items-center gap-1"
              onClick={(e) => {
                if (!$reset_pass.value) {
                }
                $reset_pass.set(!$reset_pass.value);
              }}
            >
              <BackArrow class="w-25px h-25px" />
              Back to login
            </span>
          </Show>

          <h1 class="text-black text-xl md:text-2xl font-bold py-2 md:py-3">
            <Switch fallback={"Sign in to your account" || app_flavor.id}>
              <Match when={$reset_pass.value}>Forgot password?</Match>
            </Switch>
          </h1>
          <Show when={$reset_pass.value}>
            <p class="text#hint text-17px xl:max-w-[60%]! py-2">
              Don’t worry! It happens. we will sent a link to your email to reset password.Please enter the email
              associated with your account.
            </p>
          </Show>
          <Switch fallback={null}>
            <Match when={!$totp.value} keyed={true}>
              <div class="w-full py-3 ">
                <FormLogin.Email.Label title="Email Address" class="form-label">
                  <FormLogin.Email.Error class="form-input-error" />
                </FormLogin.Email.Label>
                <FormLogin.Email.Input
                  autocomplete="username"
                  type="email"
                  class="form-input"
                  placeholder={!$reset_pass.value ? "Your email" : "Enter your email address"}
                />
              </div>
              <Show when={!$reset_pass.value}>
                <div class="w-full py-1">
                  <FormLogin.Password.Label title="Password" class="form-label">
                    <FormLogin.Password.Error class="form-input-error" />
                  </FormLogin.Password.Label>
                  <FormLogin.Password.Input
                    autocomplete="current-password"
                    type="password"
                    class="form-input "
                    placeholder="Password"
                  />
                </div>
                {/* forgot passowrd btn */}
                <span
                  class="w-full h-fit text-xs text-16px p-0 px-2 items-center justify-end text-end text#orange cursor-pointer md:(my-1) <md:(text-0.6rem my-1)"
                  onClick={(e) => {
                    $reset_pass.set(!$reset_pass.value);
                  }}
                >
                  {!$reset_pass.value ? "Forgot Password?" : "Back To Login"}
                </span>
              </Show>
            </Match>
          </Switch>

          <FormLogin.Actions.Button
            class="form-btn text-white! flex$ min-h-2rem text-0.8rem px-2 py-1 fight$ my-24px rounded-md shadow-sm bg#orange! "
            onclick={onSubmit}
            statusValid={() => ($totp.value ? "Confirm TOTP" : !$reset_pass.value ? `Login` : "Send Link")}
            statusInvalid={!$reset_pass.value ? `Login` : "Send Link"}
            // onlyStatus={["valid", "submit"]}
          />

          {/* <div class="text-xs !mt24x text-center cursor-pointer">
          Don't have an account?
          <A class=" text-16px p-0 mx-1 underline text#p" href={`..${ROUTES.auth.signup}`}>
            Create one
          </A>
        </div> */}
        </form>
      </div>

      {/* <div class="hidden md:block h-full relative md:w-[40%] ">
        <img class="object-cover w-full h-full" src={`${rectangle}`} alt="login_img" />
      </div> */}
    </section>
  );
}

import { global } from ":global";
import type { _Guarded } from ":shared/components/route";
import { platform$ } from ":src/modules/platform";

// TODO: check token only once across routes
// TODO: disable token checks on /auth but not /auth/login
// TODO: store non critical indicators in local storage to know this
export const checkPlatformSettings: _Guarded.AGuardStep = {
  runOn: ["app_init", "first_load"],
  loaderMsg: "loading platform settings...",
  async checker({ state, base, routeMatch, routeMatchBase, location }) {
    // return
    let result: _Guarded.AGuardStepCheckerError = undefined;
    await platform$.apis.getPlatformSettings().catch((error) => {
      console.error("error from check platform :: ", error);
      if (error?.response?.status !== 200) {
        result = {
          message: "an error occured while loading platform settings",
          error,
        };
      }
    });

    return result;
  },
};

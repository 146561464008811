import type {
  AGuardOnFailureProps,
  AGuardState,
  AGuardStep,
  AGuardStepCheckerError,
  AGuardStepCheckerProps,
  AGuardStepGroup,
  MainProps,
} from "../_model";
import { general } from "@qundus.tc/agnostic.helpers";

export async function checkGuardSteps<T extends AGuardStepGroup>(props: {
  steps: T;
  events: {
    next?: (step: AGuardStep) => Omit<AGuardStepCheckerProps, "state">;
    onGuardFailureSkip?: MainProps["events"]["onGuardFailureSkip"];
  };
}): Promise<
  | {
      success: boolean;
      fail?: Pick<AGuardOnFailureProps, "error" | "step" | "stepEquals"> & { key: any };
    }
  | undefined
> {
  const { events, steps } = props;
  // chek the important events
  if (events == undefined) {
    console.error("guarded: you need to set the ready event handler for guard!");
    return undefined;
  }
  if (!steps) {
    return {
      success: true,
    };
  }
  let failed_step: AGuardStep = undefined;
  let failed_step_key: any = undefined;
  let failed_error: AGuardStepCheckerError = undefined;
  for (const key in steps) {
    const step = steps[key];
    failed_step = undefined;
    failed_error = undefined;
    if (!step?.runOn || step?.runOn?.length <= 0) {
      failed_error = {
        error: "Guarded: checkGuardSteps unknown guard run on sequence! in " + step,
      };
      throw new Error(
        "Guarded: checkGuardSteps step missing runOn property and/or unknown guard run on sequence!, please specify the run on option " +
          step
      );
    } else if (!(step.runOn instanceof Set)) {
      let run_on_array = !step?.runOn
        ? undefined
        : typeof step?.runOn === "string"
        ? [step?.runOn]
        : step?.runOn?.length <= 0
        ? undefined
        : step?.runOn;
      steps[key].runOn = new Set(run_on_array) as any;
      // console.log("run on is a set now , ");
    }
    let run_on = steps[key]?.runOn as unknown as Set<AGuardState>;

    try {
      if (!step?.loaderMsg) {
        step.loaderMsg = "Unknown Step Loader Message";
      }
      const checker_props = events?.next?.(step) as AGuardStepCheckerProps;
      if (run_on.has(checker_props.state)) {
        // checker_props.state = state;
        // console.log("state is :: ", checker_props.state);
        if (step.checker == undefined || typeof step.checker != "function") {
          throw {
            message: `guarded: step <${key}> has no checker function!`,
          } as AGuardStepCheckerError;
        }
        let err = undefined;
        await Promise.race([step?.checker?.(checker_props)])
          .then((e: any) => {
            throw checkStepError({ key, err: e });
          })
          .catch((e) => {
            if (e == undefined) {
              return;
            }
            err = e;
          });
        if (err != undefined) {
          throw err;
        }
      }
    } catch (err: any) {
      // console.error(e);
      if (step?.skipFailure) {
        events?.onGuardFailureSkip?.({ step, error: err, stepEquals: (step) => stepEquals(steps, step, failed_step) });
        failed_error = undefined;
        continue;
      }
      failed_step_key = key;
      failed_step = step;
      failed_error = failed_error == undefined ? err : failed_error;
      break;
    }
  }

  if (failed_step == null) {
    return {
      success: true,
    };
  } else {
    return {
      success: false,
      fail: {
        step: failed_step,
        error: failed_error as any,
        stepEquals: (step) => stepEquals(steps, step, failed_step),
        key: failed_step_key,
      },
    };
  }
}

function stepEquals(steps: AGuardStepGroup, step1: AGuardStep, step2: AGuardStep): boolean {
  if (!steps || steps.length <= 0) {
    return false;
  }
  const s1_idx = steps.indexOf(step1);
  const s2_idx = steps.indexOf(step2);
  // console.log("comparing steps :: ", step1, step2, " :: and indecies :: ", s1_idx, s2_idx);
  return s1_idx >= 0 && s2_idx >= 0 && s1_idx === s2_idx;
}

function checkStepError(props: { key: string; err: any }): AGuardStepCheckerError {
  const { key, err } = props;
  let result: AGuardStepCheckerError = undefined;

  if (err == undefined) {
    result = undefined;
  } else if (typeof err === "string") {
    result = {
      message: err,
      error: undefined,
    };
  } else if (general.isAsyncFunction(err)) {
    result = {
      message: `step <${key}> checker function must return an error object not a promise :: `,
      error: err,
    };
    console.error(result);
  } else if (typeof err !== "object") {
    // check if not an object
    result = {
      message: `step <${key}> checker function must return an error object!!`,
      error: err,
    };
    console.error(result);
  } else {
    // check if object has required keys filled up and not just any object
    const keys = Object.keys(err);
    // err keys must be between 0 and 3
    if (keys.length <= 0 || keys.length > 3) {
      result = {
        message: `step <${key}> must be a valid step checker error object!!`,
        error: err,
      };
      console.error(result);
    } else if (!("error" in err) && !("message" in err)) {
      result = {
        message: `step <${key}> must set error's message or error keys at at least!!`,
        error: err,
      };
      console.error(result);
    }
  }

  return result ?? err;
}
